import React from 'react'
import styled, { useTheme } from 'styled-components'

const StyledHeading = styled.h2``

export const Heading = ({ children, variant, style, ...props }) => {
    const theme = useTheme();
    const textVariant = { ...((variant && theme.textVariants[variant]) || {}), ...style};

    return <StyledHeading style={textVariant} {...props}>{children}</StyledHeading>
}
