import React from 'react'
import styled from 'styled-components'

const StyledText = styled.p`

`

export const Text = ({ children, ...props }) => {
    return <StyledText {...props}>{children}</StyledText>
}
