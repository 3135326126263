import React from "react";
import PropTypes from "prop-types";
import { graphql, withPrefix } from "gatsby";

import Layout from "../components/Layout";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { theme } from "../theme/theme";
import { Image } from "../components/atoms/Image";
import { Heading } from "../components/atoms/Heading";
import { Text } from "../components/atoms/Text";

export const OverOnsPageTemplate = () => (
  <Container>
    <Row align="center" style={{minHeight: '50vh', marginTop: theme.space['10']}}>
      <Col xs={12} md={6}>
        <Image src={`${withPrefix('/')}img/over-ons-landscape.jpg`} alt="Wildwood bomen" />
      </Col>
      <Col xs={12} md={4} offset={{md: 1}}>
        <Heading as="h1" variant="heading1">Wie zijn wij</Heading>
        <Text>Wildwood is opgericht door twee Amersfoortse vrienden: Steven en Herman. Wij houden van hout, wij houden van het maken van mooie dingen en wij worden blij als we daar anderen ook weer blij mee kunnen maken. </Text>
      </Col>
    </Row>
  </Container>
);

OverOnsPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <OverOnsPageTemplate
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
